import { useTranslation } from "react-i18next";

import FullWidth from "components/common/fullWidth";

import "./CyberSecurityOversight.scss";

const CyberSecurityOversight = () => {
	const { t } = useTranslation("cyberSecurity");

	return (
		<div className="cyber-security-oversight">
			<FullWidth>
				<div className="cyber-security-oversight-container">
					<div className="title">{t("cyberSecurityOversightTitle")}</div>

					<div className="description">
						{t("cyberSecurityOversightDescription")}
					</div>
				</div>
			</FullWidth>
		</div>
	);
};

export default CyberSecurityOversight;
