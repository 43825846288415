import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import SharedApi from "api/SharedApi";

import { CaseStudyBannerFE, CaseStudyListBE, PageInfo } from "types/SharedType";

import SeoComponent from "components/common/seoComponent/SeoComponent";
import CarouselCmp from "components/common/carouselCmp";
import CustomButton from "components/common/customButton";

import HeroBanner from "components/layout/heroBanner";
import Nav from "components/layout/nav";
import Footer from "components/layout/footer";

import OurClientFilter from "./components/ourClientFilter";
import OurClientTestimonies from "./components/ourClientTestimonies";
import Partnership from "./components/partnership";

import { NotificationContext } from 'providers/notificationProvider';

import PageDataHandler from "utils/PageDataHandler";
import CaseStudiesDataHandler from "utils/CaseStudiesDataHandler";

const OurClients = () => {
	
	const { t, i18n } = useTranslation('ourClients');
	const navigate = useNavigate();
	const { displayNotification } = useContext(NotificationContext);

	const [pageInfo, setPageInfo] = useState<PageInfo>();
	const [currentFilter, setCurrentFilter] = useState<string>('All');
	const [caseStudiesList, setCaseStudiesList] = useState<CaseStudyBannerFE[]>([]);

	const pageDataHandler = PageDataHandler();
	const sharedApi = SharedApi();
	const caseStudiesDataHabdler = CaseStudiesDataHandler();
	
	const goToItem = (id: string) => {
		navigate(`/case-study/${id}`);
	}

	const getPageData = async(pageName: string, locale: string) => {
		try {
			
			const pageInfo = await pageDataHandler.getPageInfo(pageName, locale);
			pageInfo && setPageInfo(pageInfo);

		} catch (error) {
			console.error(`[Error - API] Error handling ${pageName} data`, error);
			displayNotification(`Something Went Wrong handling ${pageName} Data, Please Try Again !`, 'error');
		}
	}

	const getCaseStudiesData = async( locale: string) => {
		try {
			const caseStudiesListData: CaseStudyListBE = await sharedApi?.getCaseStudiesData(locale, [], currentFilter, 0);

			const caseStudiesList: CaseStudyBannerFE[] = caseStudiesDataHabdler.handleCaseStudiesData(caseStudiesListData?.data);
			caseStudiesList && setCaseStudiesList(caseStudiesList);

		} catch (error) {
			console.error(`[Error - API] Error handling Case Study data`, error);
			displayNotification(`Something Went Wrong handling Case Study Data, Please Try Again !`, 'error');
		}
	}

	useEffect(() => {
		getPageData('clients-page', i18n.language);
		// eslint-disable-next-line
	}, [i18n.language])

	useEffect(() => {
		getCaseStudiesData(i18n.language);
		// eslint-disable-next-line
	}, [i18n.language, currentFilter])

	const breadCrumb = `{
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [
			{
				"@type": "ListItem",
				"position": 1,
				"item": {
						"@id": "${window.location.origin}",
						"name": "4i Tech: Home"
					}
			},
			{
				"@type": "ListItem",
				"position": 2,
				"item": {
						"@id": "${window.location.origin}",
						"name": "4i Tech: Our Clients"
					}
			}
		]
	}`;

	const ourClientsSchema = `{
		"@context":"https://schema.org",
		"@type": "WebPage",
		"@id": "${window.location.href}",
		"url": "${window.location.href}",
		"name": "${pageInfo?.seo?.metaTitle}",
		"description": "${pageInfo?.seo?.metaDescription}",
		"inLanguage": "${i18n.language}",
		"isPartOf":{
			"@type":"WebSite",
			"name":"4i Tech",
			"url":"${window.location.origin}"         
		}
	}`;
	
	return (
		<>
			{pageInfo &&
				<SeoComponent
					metaTitle={pageInfo?.seo?.metaTitle}
					metaDescription={pageInfo?.seo?.metaDescription}
					metaImage={pageInfo?.seo?.metaImage}
					metaSocial={pageInfo?.seo?.metaSocial}
					canonicalURL={pageInfo?.seo?.canonicalURL}
					keywords={pageInfo?.seo?.keywords}
					metaRobots={pageInfo?.seo?.metaRobots}
					metaViewport={pageInfo?.seo?.metaViewport}
					structuredData={pageInfo?.seo?.structuredData}
					breadCrumb={breadCrumb}
					mainEntityOfPage={ourClientsSchema}
				/>
			}

			<Nav />

			<HeroBanner 
				picture={pageInfo?.detail?.bannerImage?.url || ''}
				title={pageInfo?.detail?.title || ''}
				description={pageInfo?.detail?.description || ''}
			/>

			<OurClientFilter 
				currentFilter={currentFilter}
				setCurrentFilter={setCurrentFilter}
			/>

			{caseStudiesList && caseStudiesList?.length > 0 &&			

        <CarouselCmp
          title={t('caseStudiesCmpTitle')}
          listItem={caseStudiesList}
          description={t('caseStudiesCmpDescription') as string}
          onClickFunc={goToItem}
          extraContent={
						<CustomButton
							onClickBtn={() => navigate('/case-studies')}
						>
							{t('caseStudiesCmpBtn')}
						</CustomButton>
				  }
				/>
			}

			<OurClientTestimonies />

			<Partnership />

			<Footer />
		</>
	);
}

export default OurClients;