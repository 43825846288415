import { useContext } from 'react';
import { NotificationContext } from 'providers/notificationProvider';

import axios from 'axios';

const ToolsAPI = () => {

	const { displayNotification } = useContext(NotificationContext);

    const getToolListData = async (pageNum = 0) => {

        let params:string = `&pagination[start]=${pageNum && pageNum * 7}&pagination[limit]=7`;

        const result = await axios.get(process.env.REACT_APP_STRAPI_API_URL + `/tools?populate=logo.logo&filters[logo][id][$gte]=1${params}`)
        .then(response => {
            return response?.data;
        }).catch(error => {
            displayNotification('Something Went Wrong Fetching Tools List Data, Please Try Again !', 'error');
            console.error("[Error - API] Error calling Tools List Data", error);
        });

        return result;
    }

	return {
		getToolListData: getToolListData
	};
}

export default ToolsAPI;