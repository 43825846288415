import { useTranslation } from 'react-i18next';

import PageWrapper from 'components/common/pageWrapper';
import DoubleCircleOverlay from 'components/common/doubleCircleOverlay';
import BlurCircle from 'components/common/blurCircle';

import CeoPicture from 'assets/img/ceo.png';

import './Ceo.scss';

const Ceo = () => {
	const {t} = useTranslation('aboutUs');

	return (
		<PageWrapper className='ceo small'>
			<BlurCircle className='blur-circle-container' size="922px" />

			<DoubleCircleOverlay size="1541px" className='double-circle-container'/>
			
			<div className='ceo-picture-container'>
				<img 
					src={CeoPicture}
					alt='Corrado von Planta, 4i Tech CEO'
				/>
			</div>

			<div className='tag'>
				{t('ceoTag')}
			</div>

			<div className='name'>
				{t('ceoName')}
			</div>

			<div className='description'>
				{t('ceoDescription')}
			</div>
		</PageWrapper>
	);
}

export default Ceo;