import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

import RichTextTransformCmp from "components/common/richTextTransformCmp";
import RichTextStylingCmp from "components/common/richTextStylingCmp";

import Nav from "components/layout/nav";
import Footer from "components/layout/footer";
import SimpleTextPage from "components/layout/simpleTextPage";

const PrivacyPolicy = () => {
	const { t } = useTranslation("privacyPolicy");

	return (
		<>
			<Helmet>
				<meta name="robots" content="noindex" />
			</Helmet>

			<Nav isBgWhite={true} />

			<SimpleTextPage linkLabel={t("goBackLink")}>
				<h1>{t("title")}</h1>

				<RichTextStylingCmp>
					<RichTextTransformCmp>{t("content")}</RichTextTransformCmp>
				</RichTextStylingCmp>
			</SimpleTextPage>

			<Footer />
		</>
	);
};

export default PrivacyPolicy;
