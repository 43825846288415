import { useContext } from 'react';
import { NotificationContext } from 'providers/notificationProvider';

import axios from 'axios';

const CaseStudyApi = () => {

	const { displayNotification } = useContext(NotificationContext);

    const getCaseStudyData = async (id: string, locale: string) => {
        const result = await axios.get(process.env.REACT_APP_STRAPI_API_URL + `/case-studies?populate=seo.metaImage,seo.metaSocial.image,banner_image,case_study_types,client.client_industries,tools,images,localizations&locale=${locale}&filters[url_path]=${id}`)
        .then(response => {
            return response?.data?.data[0]?.attributes;
        }).catch(error => {
            displayNotification(`Something Went Wrong Fetching Case Study Data, Please Try Again !`, 'error');
            console.error(`[Error - API] Error calling Case Study data`, error);
        });
        return result;
    }

	return {
		getCaseStudyData: getCaseStudyData,
	};
}

export default CaseStudyApi;