import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationContext } from 'providers/notificationProvider';

import InsightsListHandler from 'utils/InsightsListHandler';

import InsightApi from 'api/InsightApi';

import { CaseStudyListBE } from 'components/pages/insights/components/listInsights/SharedType';
import { InsightFE } from 'components/common/insightItem/sharedType';

import PageWrapper from 'components/common/pageWrapper';
import InsightItem from 'components/common/insightItem';

import './LatestInsights.scss';


const LatestInsights = () => {
	const { t, i18n } = useTranslation('insights');

	const { displayNotification } = useContext(NotificationContext);

	const insightApi = InsightApi();
	const insightsListHandler = InsightsListHandler();

	const [latestInsightsList, setLatestInsightsList] = useState<InsightFE[]>();

	const getInsightsList = async() => {
		try {
			const insightsListData:CaseStudyListBE = await insightApi.getInsightsListData(0, [], true, 3, i18n.language);			
			
			const insightList = insightsListHandler.handleInsightList(insightsListData?.data);

			insightList && setLatestInsightsList(insightList);

		} catch (error) {
			displayNotification(`Something Went Wrong Fetching Insight Data, Please Try Again !`, 'error');
			console.error(`[Error - API] Error calling Insight data`, error);
		}
	}

	useEffect(() => {
		getInsightsList();
		// eslint-disable-next-line
	}, [i18n.language])
	
	return (
		<>
			{latestInsightsList && latestInsightsList?.length > 0 &&
				<PageWrapper className='latest-insights'>
					<div className='title-latest'>
						{t('latestInsightTitle')}
					</div>

					<div className='latest-insights-container'>
						{latestInsightsList?.map((insight, key) => {
							return (
								<div className='item-container' key={key}>
									<InsightItem
										item={{
											urlPath: insight?.urlPath,
											type: insight?.type,
											publishedDate: insight?.publishedDate,
											title: insight?.title,
											paragraph: insight?.paragraph
										}}
										withBg={key===0}
									/>
								</div>
							)
						})}
					</div>
			
				</PageWrapper>
			}
		</>
	);
}

export default LatestInsights;