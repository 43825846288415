import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SeoComponent from "components/common/seoComponent/SeoComponent";

import Nav from "components/layout/nav";
import HeroVideoBanner from "components/layout/heroVideoBanner";
import SimpleText from "components/layout/simpleText";
import GetInTouchCmp from "components/layout/getInTouchCmp";
import Footer from "components/layout/footer";
import Numbers from "components/layout/numbers";
import HeroBanner from "components/layout/heroBanner";

import AllSolution from "./components/allSolution";
import LearnMore from "./components/learnMore";
import AllAwards from "./components/allAwards";
import MainClients from "./components/mainClients";
import HomePageTestimonies from "./components/homePageTestimonies";

import { HomeDataContext } from "providers/homeDataProvider/HomeDataProvider";
import { BtnStyles } from "components/common/customButton/SharedTypes";

import variables from "styles/_other.module.scss";

const Homepage = () => {
	const { t, i18n } = useTranslation("homepage");
	const navigate = useNavigate();
	const [isMobile, setIsMobile] = useState(false);

	const { seo, detail, awards, init } = useContext(HomeDataContext);

	useEffect(() => {
		init();
		// eslint-disable-next-line
	}, [i18n.language]);

	useEffect(() => {
		const phoneSize = parseInt(variables.mediaQueryPhone.slice(0, -2));

		const handleResize = () => {
			if (window.innerWidth <= phoneSize) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		};

		window.addEventListener("resize", handleResize);

		handleResize();

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const bannerBtnList = [
		{
			btnTxt: t("bannerBtnTalkWithUs"),
			onClickBtn: () => navigate("/contact-us"),
		},
		{
			btnTxt: t("bannerBtnSolutions"),
			onClickBtn: () => navigate("/solutions"),
			btnStyle: BtnStyles.SECONDARY,
		},
	];

	const breadCrumb = `{
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [
			{
				"@type": "ListItem",
				"position": 1,
				"item": {
						"@id": "${window.location.origin}",
						"name": "4i Tech: Home"
					}
			}
		]
	}`;

	const homeSchema = `{
		"@context":"https://schema.org",
		"@type": "WebPage",
		"@id": "${window.location.href}",
		"url": "${window.location.href}",
		"name": "${seo?.metaTitle}",
		"description": "${seo?.metaDescription}",
		"inLanguage": "${i18n.language}"
	}`;

	return (
		<>
			{seo && (
				<SeoComponent
					metaTitle={seo?.metaTitle}
					metaDescription={seo?.metaDescription}
					metaImage={seo?.metaImage}
					metaSocial={seo?.metaSocial}
					canonicalURL={seo?.canonicalURL}
					keywords={seo?.keywords}
					metaRobots={seo?.metaRobots}
					metaViewport={seo?.metaViewport}
					structuredData={seo?.structuredData}
					breadCrumb={breadCrumb}
					mainEntityOfPage={homeSchema}
				/>
			)}

			<Nav />

			{isMobile ? (
				<HeroBanner
					picture={detail?.bannerImage?.url}
					title={detail?.title}
					description={detail?.description}
				/>
			) : (
				<HeroVideoBanner
					linkVideo={detail?.bannerVideo?.url}
					videoType={detail?.bannerVideo?.type}
					title={detail?.title}
					subtitle={detail?.subtitle}
					description={detail?.description}
					btnList={bannerBtnList}
				></HeroVideoBanner>
			)}

			<SimpleText>
				{t("descriptionTxt")}
				<span className="colored">{t("descriptionHilighted")}</span>
			</SimpleText>

			<AllSolution />

			<LearnMore />

			{awards && <AllAwards awards={awards} />}

			<Numbers withBackgroundColor={true} />

			<HomePageTestimonies />

			<MainClients />

			<GetInTouchCmp />

			<Footer />
		</>
	);
};

export default Homepage;
