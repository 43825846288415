import { useContext } from 'react';
import axios from 'axios';

import { NotificationContext } from 'providers/notificationProvider';

const SharedApi = () => {
	const { displayNotification } = useContext(NotificationContext);

	const getNumbers = async () => {
		const result = await axios.get(process.env.REACT_APP_STRAPI_API_URL + `/by-the-number`)
		.then(response => {
			return response?.data?.data?.attributes;
		}).catch(error => {
			displayNotification('Something Went Wrong Fetching Numbers, Please Try Again !', 'error');
			console.error("[Error - API] Error calling getNumbers", error);
		});

		return result;
	}

	const getClients = async (filter:string, pageNum:number, locale:string) => {
		let params:string = '&pagination[limit]=8';

		if(filter === 'NoPagination'){
			params = '';
		}else if(filter === 'All'){
			params = `&pagination[start]=${pageNum && pageNum * 8}` + params;
		}else{
			params = `&filters[client_industries][name]=${filter}&pagination[start]=${pageNum && pageNum * 8}` + params;
		}

		const result = await axios.get(process.env.REACT_APP_STRAPI_API_URL + `/clients?locale=${locale}&populate=*${params}`)
		.then(response => {
			return response?.data;
		}).catch(error => {
			displayNotification('Something Went Wrong Fetching Clients, Please Try Again !', 'error');
			console.error("[Error - API] Error calling getClients", error);
		});

		return result;
	}

	const getWrittenTestimonial = async(locale: string) => {
		const result = await axios.get(process.env.REACT_APP_STRAPI_API_URL + `/client-testimonials?locale=${locale}&populate=testimonial.client_image&filters[type]=written`)
		.then(response => {
			return response?.data?.data;
		}).catch(error => {
			displayNotification('Something Went Wrong Fetching Written Testimonials, Please Try Again !', 'error');
			console.error("[Error - API] Error calling getWrittenTestimonial", error);
		});

		return result;
	}

	const getVideoTestimonial = async(locale: string) => {
		const result = await axios.get(process.env.REACT_APP_STRAPI_API_URL + `/client-testimonials?locale=${locale}&populate=testimonial.video,testimonial.thumbnail&filters[type]=video`)
		.then(response => {
			return response?.data?.data;
		}).catch(error => {
			displayNotification('Something Went Wrong Fetching Video Testimonials, Please Try Again !', 'error');
			console.error("[Error - API] Error calling getVideoTestimonial", error);
		});

		return result;
	}

	const getCaseStudiesData = async(locale: string, typeFilterList?:string[], industryFilter?:string, pageNum = 0, notInclude?: string) => {

		let params:string = `&pagination[start]=${pageNum && pageNum * 6}`;

		if(typeFilterList && typeFilterList?.length > 0){

			typeFilterList?.forEach((typeFilter) => {
			
				if(typeFilter !== '' && typeFilter !== 'All' && typeFilter !== undefined){
					
					params = `&filters[case_study_types][name]=${typeFilter}` + params;
					
				}

			})
			
		}

		if(industryFilter !== '' && industryFilter !== 'All' && industryFilter !== undefined ){

			params = `&filters[client][client_industries][name]=${industryFilter}` + params;
			
		}

		if(notInclude && notInclude !== ''){
			params = `&filters[url_path][$ne]=${notInclude}` + params;
		}

		const result = await axios.get(process.env.REACT_APP_STRAPI_API_URL + `/case-studies?locale=${locale}&populate=banner_image,case_study_types,images,client.client_industries,localizations,${params}&pagination[limit]=6`)
		.then(response => {
			return response?.data;
		}).catch(error => {
			displayNotification('Something Went Wrong Fetching Case Studies, Please Try Again !', 'error');
			console.error("[Error - API] Error calling getCaseStudiesData", error);
		});

		return result;
	}

	const getCaseStudyTypesData = async(locale: string) => {
		const result = await axios.get(process.env.REACT_APP_STRAPI_API_URL + `/case-study-types?locale=${locale}&sort=name`)
		.then(response => {
			return response?.data?.data;
		}).catch(error => {
			displayNotification('Something Went Wrong Fetching Client Types, Please Try Again !', 'error');
			console.error("[Error - API] Error calling getClientTypes", error);
		});

		return result;
	}

	const getClientIndustries = async(locale: string) => {
		const result = await axios.get(process.env.REACT_APP_STRAPI_API_URL + `/industries?locale=${locale}&populate=*&pagination[limit]=-1&sort=name`)
		.then(response => {
			return response?.data?.data;
		}).catch(error => {
			displayNotification('Something Went Wrong Fetching Client Industries, Please Try Again !', 'error');
			console.error("[Error - API] Error calling getClientIndustries", error);
		});

		return result;
	}


	return {
		getNumbers: getNumbers,
		getClients: getClients,
		getVideoTestimonial: getVideoTestimonial,
		getWrittenTestimonial: getWrittenTestimonial,
		getCaseStudiesData: getCaseStudiesData,
		getCaseStudyTypesData: getCaseStudyTypesData,
		getClientIndustries: getClientIndustries,
	};
}

export default SharedApi;