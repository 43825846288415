import { useTranslation } from 'react-i18next';

import PageWrapper from 'components/common/pageWrapper';

import AboutUsImg from 'assets/img/aboutUs.png';

import './AboutUsDescription.scss';

const AboutUsDescription = () => {
	const {t} = useTranslation('aboutUs');

	return (
		<div className='about-us-description'>
			<div className='picture-container'>
				<img src={AboutUsImg} alt='Decorate desktop with computer' />
			</div>

			<PageWrapper className='about-us-description-container'>
				<div className='content-container'>
					<div className='tag'>
						{t('aboutUsTag')}
					</div>

					<div className='title'>
						{t('aboutUsTitle')}
					</div>

					<div className='description'>
						{t('aboutUsDescription')}
					</div>
				</div>
			</PageWrapper>
		</div>
	);
}

export default AboutUsDescription;