import PageWrapper from "components/common/pageWrapper";

import "./PageNotFound.scss";

const PageNotFound = () => {
	return (
		<PageWrapper className="page-not-found">
			<div className="title">404 Error</div>

			<div className="sub-title">Page not found</div>

			<div className="content">
				Let's take you back :{" "}
				<a href="/" className="home-page-link">
					Homepage
				</a>
			</div>
		</PageWrapper>
	);
};

export default PageNotFound;
