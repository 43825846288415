import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

import Nav from "components/layout/nav";
import Notice from "components/layout/notice/Notice";

const ContactUsInquiryNotice = () => {
	const { t } = useTranslation("thankYou");
	const navigate = useNavigate();

	setTimeout(() => {
		navigate("/");
	}, 5000);

	return (
		<>
			<Helmet>
				<meta name="robots" content="noindex" />
			</Helmet>

			<Nav isBgWhite={true} />

			<Notice
				tag={t("tag")}
				title={t("title")}
				subtitle={t("subtitle")}
				success={true}
			/>
		</>
	);
};

export default ContactUsInquiryNotice;
