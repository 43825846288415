import { useNavigate } from 'react-router-dom';

import Nav from 'components/layout/nav';
import Notice from 'components/layout/notice/Notice';
import { useTranslation } from 'react-i18next';

const WhitePaperInquiryNotice = () => {
	const { t } = useTranslation('whitePaper');
	const navigate = useNavigate();

	setTimeout(() => {
		navigate(-1);
	}, 5000);

	return (
		<>
			<Nav isBgWhite={true} />
			<Notice 
				tag={t("tankYouTag")}
				title={t("thankYouTitle")}
				subtitle={t("thankYouSubtitle")}
				success={true}
			/>
		</>
	)
}

export default WhitePaperInquiryNotice;