import { useTranslation } from 'react-i18next';

import { ToolTypeFE } from 'types/SharedType';

import ToolsListCmp from 'components/layout/toolsListCmp';

import './ProjectManagementTools.scss';
import CustomButton from 'components/common/customButton';

interface Props {
	toolsList: ToolTypeFE[];
	canLoadMore: boolean;
	loadMore: () => void;
}

const ProjectManagementTools = ({toolsList, canLoadMore, loadMore}: Props) => {

	const {t} = useTranslation('projectManagement');

	return (
		<>
			{toolsList && toolsList?.length > 0 &&
				<div className='project-management-tools'>
					<ToolsListCmp
						title={t('toolsTag')}
						tag={t('toolsTitle')}
						description={t('toolsDescription') as string}
						list={toolsList}
					/>
					{canLoadMore &&
						<div className='load-more-btn'>
								<CustomButton
									onClickBtn={() => loadMore()}
								>
									{t('loadMoreBtn')}
								</CustomButton>
						</div>
					}
			</div>
			}
		</>
	);
}

export default ProjectManagementTools;