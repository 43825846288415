import { useContext } from 'react';
import { NotificationContext } from 'providers/notificationProvider';

import axios from 'axios';

const InsightApi = () => {

	const { displayNotification } = useContext(NotificationContext);

	const getInsightData = async(id: string, locale: string) => {
		const result = await axios.get(process.env.REACT_APP_STRAPI_API_URL + `/insights?populate=seo.metaImage,seo.metaSocial.image,image,insight_types,elements.image_one,elements.image_two,elements.image,localizations&locale=${locale}&filters[url_path]=${id}`)
		.then(response => {
				return response?.data?.data[0]?.attributes;
		}).catch(error => {
				displayNotification(`Something Went Wrong Fetching Insight Data, Please Try Again !`, 'error');
				console.error(`[Error - API] Error calling Insight data`, error);
		});
		return result;
	}

	const getInsightsListData = async(pageNum: number = 0, typeList: string[] = [], isDesc: boolean = false, itemPerPage: number = 3, locale: string, notInclude?: string) => {

		let params:string = ``;

		if(isDesc){
			params = `&sort=published:desc&pagination[limit]=${itemPerPage}`;
		}else{
			params = `&sort=published:asc&pagination[start]=${pageNum * itemPerPage}&pagination[limit]=${itemPerPage}`;
		}

		if(typeList?.length > 0){
			typeList?.map((type) => {
				if(type !== 'All'){
					return params = `&filters[insight_types][type]=${type}` + params;
				}
				return null;
			})
		}
		
		if(notInclude && notInclude !== ''){
			params = `&filters[url_path][$ne]=${notInclude}` + params;
		}

		const result = await axios.get(process.env.REACT_APP_STRAPI_API_URL + `/insights?locale=${locale}&populate=insight_types,elements${params}`)
		.then(response => {
				return response?.data;
		}).catch(error => {
				displayNotification(`Something Went Wrong Fetching Insight List Data, Please Try Again !`, 'error');
				console.error(`[Error - API] Error calling Insight List data`, error);
		});
		return result;
	}

	const getInsightsTypeData = async (locale: string) => {
		const result = await axios.get(process.env.REACT_APP_STRAPI_API_URL + `/insight-types?locale=${locale}`)
		.then(response => {
				return response?.data?.data;
		}).catch(error => {
				displayNotification(`Something Went Wrong Fetching Insight Type Data, Please Try Again !`, 'error');
				console.error(`[Error - API] Error calling Insight Type data`, error);
		});
		return result;
	}

	return {
		getInsightData: getInsightData,
		getInsightsListData: getInsightsListData,
		getInsightsTypeData: getInsightsTypeData,
	};
}

export default InsightApi;