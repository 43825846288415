import { ReactNode } from 'react';

import { BtnStyles } from './SharedTypes';

import './CustomButton.scss';

interface Props {
	children: ReactNode;
	onClickBtn: () => void;
	btnStyle?: string;
	className?: string;
	icon?: string;
}

const CustomButton = ({children, onClickBtn, btnStyle=BtnStyles.PRIMARY, className='', icon}: Props) => {
	return (
		<>
			<button
				className={`btn ${btnStyle} ${className} ${icon && 'with-icon'}`}
				onClick={() => onClickBtn()}
				style={{backgroundImage: `url(${icon})`}}
			>
				{children}
			</button>
		</>
	);
}

export default CustomButton;