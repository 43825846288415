import PixelarCheckSvg from 'assets/icons/pixelarCheck.svg';

import './PixelarCheck.scss';

const PixelarCheck = () => {
	return (
		<div className='check-container'>
			<img alt='check-icon' className='check-svg' src={PixelarCheckSvg}/>
		</div>
	)
}

export default PixelarCheck;