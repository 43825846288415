import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CaseStudyBannerFE } from 'types/SharedType';

import CarouselCmp from 'components/common/carouselCmp';

import './CaseStudyCaseStudiesList.scss';

interface Props {
	caseStudyList?: CaseStudyBannerFE[];
}

const CaseStudyCaseStudiesList = ({caseStudyList}: Props) => {
	const {t} = useTranslation('caseStudies');
	const navigate = useNavigate();
		
	const goToItem = (id: string) => {
		navigate(`/case-study/${id}`);
	}
	
	return (
		<div className="case-study-case-studies-list">
			<CarouselCmp
				title={t('caseStudiesCmpTitle')}
				isDarkBg={true}
				listItem={caseStudyList}
				onClickFunc={goToItem}
			/>
		</div>
	);
}

export default CaseStudyCaseStudiesList;