import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { NotificationContext } from "providers/notificationProvider";

import PageDataHandler from "utils/PageDataHandler";
import InsightsListHandler from "utils/InsightsListHandler";

import { PageInfo } from "types/SharedType";
import { InsightFE } from "components/common/insightItem/sharedType";

import Nav from "components/layout/nav";
import Footer from "components/layout/footer";
import HeroBanner from "components/layout/heroBanner";
import SeoComponent from "components/common/seoComponent/SeoComponent";
import ListInsights from "./components/listInsights/ListInsights";

const Insights = () => {

	const { i18n } = useTranslation();
	const { displayNotification } = useContext(NotificationContext);

	const [pageInfo, setPageInfo] = useState<PageInfo>();
	const [selectedInsights, setSelectedInsights] = useState<InsightFE[]>();

	const pageDataHandler = PageDataHandler();	
	const insightsListHandler = InsightsListHandler();

	const getPageData = async(pageName: string, locale: string) => {
		try {
			
			const pageInfo = await pageDataHandler.getPageInfo(pageName, locale);

			const insightList = insightsListHandler.handleInsightList(pageInfo?.pageData?.selected_insights?.insights?.data);

			insightList && setSelectedInsights(insightList);
			pageInfo && setPageInfo(pageInfo);

		} catch (error) {
			console.error(`[Error - API] Error calling ${pageName} data`, error);
			displayNotification(`Something Went Wrong Fetching ${pageName} Data, Please Try Again !`, 'error');
		}
	}

	useEffect(() => {
		getPageData('insights-page', i18n.language);
		// eslint-disable-next-line
	}, [i18n.language])

	const breadCrumb = `{
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [
			{
				"@type": "ListItem",
				"position": 1,
				"item": {
						"@id": "${window.location.origin}",
						"name": "4i Tech: Home"
					}
			},
			{
				"@type": "ListItem",
				"position": 2,
				"item": {
						"@id": "${window.location.href}",
						"name": "4i Tech: Insights"
					}
			}
		]
	}`;

	const insightsSchema = `{
		"@context":"https://schema.org",
		"@type": "WebPage",
		"@id": "${window.location.href}",
		"url": "${window.location.href}",
		"name": "${pageInfo?.seo?.metaTitle}",
		"description":"${pageInfo?.seo?.metaDescription}",
		"inLanguage": "${i18n.language}",
		"isPartOf":{
			"@type":"WebSite",
			"name":"4i Tech",
			"url":"${window.location.origin}"
		}
	}`;

	return (
		<>
			<Nav />
			
			{pageInfo &&
				<SeoComponent
					metaTitle={pageInfo?.seo?.metaTitle}
					metaDescription={pageInfo?.seo?.metaDescription}
					metaImage={pageInfo?.seo?.metaImage}
					metaSocial={pageInfo?.seo?.metaSocial}
					canonicalURL={pageInfo?.seo?.canonicalURL}
					keywords={pageInfo?.seo?.keywords}
					metaRobots={pageInfo?.seo?.metaRobots}
					metaViewport={pageInfo?.seo?.metaViewport}
					structuredData={pageInfo?.seo?.structuredData}
					breadCrumb={breadCrumb}
					mainEntityOfPage={insightsSchema}
				/>
			}

			<HeroBanner 
				picture={pageInfo?.detail?.bannerImage?.url}
				title={pageInfo?.detail?.title}
				description={pageInfo?.detail?.description}
			/>

			<ListInsights mainInsightsList={selectedInsights}/>

			<Footer />
		</>
	);
}

export default Insights;