import { useContext } from 'react';
import { NotificationContext } from 'providers/notificationProvider';

import axios from 'axios';
import { WhitePaperFormPayloadType } from 'components/pages/whitePaper/WhitePaperTypes';

const WhitePaperFormApi = () => {

	const { displayNotification } = useContext(NotificationContext);

    const submitWhitePaperForm = async (formData: WhitePaperFormPayloadType) => {
        const result = await axios.post(process.env.REACT_APP_STRAPI_API_URL + `/white-paper-requests`, 
				{
					data: formData
				})
        .then(response => {
            return response;
        }).catch(error => {
            displayNotification(`Something Went Wrong Fetching Case Study Data, Please Try Again !`, 'error');
            console.error(`[Error - API] Error calling Case Study data`, error);
        });
        return result;
    }

	return {
		submitWhitePaperForm: submitWhitePaperForm,
	};
}

export default WhitePaperFormApi;