import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PageWrapper from 'components/common/pageWrapper';
import CustomButton from 'components/common/customButton';

import FrontdImg from 'assets/img/ourClients1.webp';
import BehindImg from 'assets/img/ourClients2.png';

import './Partnership.scss';

const Partnership = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('ourClients');
	
	return (
		<div className='partnership'>
			<img 
				className='behind-picture'
				src={BehindImg}
				alt="bionic eyes"
			/>
			
			<PageWrapper className='partnership-container'>
				<div className='introduction'>
					<div className='introduction-container'>
						<div className='tag'>
							{t('partnershipTag')}
						</div>

						<div className='title'>
							{t('partnershipTitle')}
						</div>

						<div className='description'>
							{t('partnershipDescription')}
						</div>

						<CustomButton
							onClickBtn={() => navigate('/contact-us')}
						>
							{t('patnershiptBtn')}
						</CustomButton>
					</div>
				</div>

				<div className='picture-container'>
					<img 
						className="front-picture"
						src={FrontdImg}
						alt="bionic eyes"
					/>
				</div>
			</PageWrapper>
		</div>
	);
}

export default Partnership;