import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import SeoDataHandler from "utils/SeoDataHandler";
import InsightsListHandler from "utils/InsightsListHandler";

import { Image, ImageFE, SeoBE, SeoFE } from "types/SharedType";

import InsightApi from "api/InsightApi";

import CompanyLogo from "assets/img/logo192.png";

import { NotificationContext } from "providers/notificationProvider";

import RichTextTransformCmp from "components/common/richTextTransformCmp";
import RichTextStylingCmp from "components/common/richTextStylingCmp";

import SeoComponent from "components/common/seoComponent/SeoComponent";
import Nav from "components/layout/nav";
import Footer from "components/layout/footer";
import GetStarted from "components/layout/getStarted";

import InsightBanner from "./components/insightBanner";
import InsightContent from "./components/insightContent/InsightContent";
import InsightText from "./components/insightContent/components/insightText";
import InsightPictures from "./components/insightContent/components/insightPictures";
import RelatedInsight from "./components/relatedInsight";

interface ElementParagraphBE {
	id: number;
	paragraph: string;
	__component: 'individual-insight.paragraph';
}

interface ElementDoubleImageBE {
	id: number;
	image_one: Image;
	image_two: Image;
	__component: 'individual-insight.double-image';
}

interface ElementSingleImageBE {
	id: number;
	image: Image;
	__component: 'individual-insight.single-image';
}

type ElementBE = ElementParagraphBE | ElementDoubleImageBE | ElementSingleImageBE;

interface InsightDataBE {
	seo: SeoBE;
	title: string;
	elements: ElementBE[];
	published: string;
	image: Image;
	locale: string;
	insight_types: {
		data: {
			id: number;
			attributes: {
				type: string;
			}
		}[]
	}
	localizations: {
		data: {
			id: number;
			attributes: {
				locale: string;
			}
		}[]
	}
	publishedAt: string;
	updatedAt: string;
}

interface ElementParagraphFE {
	paragraph: string;
	name: string;
}

interface ElementDoubleImageFE {
	image_one: ImageFE;
	image_two: ImageFE;
	name: string;
}

interface ElementSingleImageFE {
	image: ImageFE;
	name: string;
}

type ElementFE = ElementParagraphFE | ElementDoubleImageFE | ElementSingleImageFE | undefined;

interface InsightDataFE {
	title: string;
	type: string[];
	image: ImageFE;
	locale: string;
	publishedDate: string;
	updatedAt: string;
	publishedAt: string;
	elementsList:  ElementFE[] | undefined;
	localizations: {
		attributes: {
			locale: string;
		}
		id: number;
	}[]
}

const insightDataHandler  = (insightData:InsightDataBE) => {

	const elementsList: ElementFE[] = insightData?.elements?.map(element => {
		if(element?.__component === 'individual-insight.paragraph'){
			return{
				paragraph: element?.paragraph,
				name: element?.__component,
			}
		}else if(element?.__component === 'individual-insight.double-image'){
			return{
				name: 'individual-insight.double-image',
				image_one: {
					url: `${process.env.REACT_APP_STRAPI_URL}${element?.image_one?.data?.attributes?.url}`,
					caption: element?.image_one?.data?.attributes?.caption,
					alternativeText: element?.image_one?.data?.attributes?.alternativeText,
				},
				image_two: {
					url: `${process.env.REACT_APP_STRAPI_URL}${element?.image_two?.data?.attributes?.url}`,
					caption: element?.image_two?.data?.attributes?.caption,
					alternativeText: element?.image_two?.data?.attributes?.alternativeText,
				}
			}
		}else if(element?.__component === 'individual-insight.single-image'){
			return{
				name: 'individual-insight.single-image',
				image:{
					url: `${process.env.REACT_APP_STRAPI_URL}${element?.image?.data?.attributes?.url}`,
					caption: element?.image?.data?.attributes?.caption,
					alternativeText: element?.image?.data?.attributes?.alternativeText,
				}
			}
		}
		return undefined;
	})

	return{
		title: insightData?.title,
		type: insightData?.insight_types?.data?.map(type =>  type?.attributes?.type),
		locale: insightData?.locale,
		image: {
			url: `${process.env.REACT_APP_STRAPI_URL}${insightData?.image?.data?.attributes?.url}`,
			caption: insightData?.image?.data?.attributes?.caption,
			alternativeText: insightData?.image?.data?.attributes?.alternativeText,
		},
		publishedDate: insightData?.published,
		elementsList: elementsList,
		localizations: insightData?.localizations?.data,
		publishedAt: insightData?.publishedAt,
		updatedAt: insightData?.updatedAt,
	}

}

const Insight = () => {

	const { displayNotification } = useContext(NotificationContext);
	const {i18n} = useTranslation();
	const {id} = useParams();

	const [seo, setSEO] = useState<SeoFE>();
	const [insight, setInsight] = useState<InsightDataFE>();
	const [relatedInsightsList, setRelatedInsightsList] = useState<any>();

	const insightApi = InsightApi();
	const seoDataHandler = SeoDataHandler();
	const insightsListHandler = InsightsListHandler();

	const getInsight = async() => {
		try {

			if(id){
				const insightData: InsightDataBE = await insightApi.getInsightData(id, i18n.language);
				
				const seo: SeoFE = seoDataHandler.handleSeoData(insightData?.seo);
				const insight: InsightDataFE = insightDataHandler(insightData);

				seo && setSEO(seo);
				insight && setInsight(insight);
			}

		} catch (error) {
			displayNotification(`Something Went Wrong Fetching Insight Data, Please Try Again !`, 'error');
			console.log(error);
		}
	}

	const getInsightsList = async() => {

		const relatedInsightsListData = await insightApi.getInsightsListData(0, insight?.type, true, 3, i18n.language, id);

		const insightList = insightsListHandler.handleInsightList(relatedInsightsListData?.data);

		setRelatedInsightsList(insightList);
	}

	useEffect(() => {
		getInsight();
		getInsightsList();
		// eslint-disable-next-line
	}, [id, i18n.language])

	const breadCrumb = `{
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [
			{
				"@type": "ListItem",
				"position": 1,
				"item": {
						"@id": "${window.location.origin}",
						"name": "4i Tech: Home"
					}
			},
			{
				"@type": "ListItem",
				"position": 2,
				"item": {
						"@id": "${window.location.origin}/insights",
						"name": "4i Tech: Insights"
					}
			},
			{
				"@type": "ListItem",
				"position": 3,
				"item": {
						"@id": "${window.location.href}",
						"name": "4i Tech Insight: ${window.location.pathname.split('/')[2].replace("-", " ")}"
					}
			}
		]
	}`;

	const articleSchema = `{
		"@context": "https://schema.org",
		"@type": "WebPage",
		"@id": "${window.location.href}",
		"url": "${window.location.href}",
		"name": "${seo?.metaTitle}",
		"description": "${seo?.metaDescription}",
		"about": {
			"@type": "NewsArticle",
			"headline": "${insight?.title}",
			"description": "${seo?.metaDescription}",
			"author": {
				"@type": "Organization",
				"name": "4i Tech",
				"url": "${window.location.origin}"
			},
			"publisher": {
				"@type": "Organization",
				"name": "4i Tech",
				"logo": {
					"@type": "ImageObject",
					"url": "${CompanyLogo}"
				}
			},
			"datePublished": "${insight?.publishedAt}",
			"dateModified": "${insight?.updatedAt}",
			"image": "${insight?.image?.url}",
			"articleBody": "${insight?.elementsList?.map((element: any, i) =>{ 
				if(element?.name === 'individual-insight.paragraph'){
					return element?.paragraph
				}
			})}"
		}
	}`;

	return (
		<>

			{seo &&	
				<SeoComponent
					metaTitle={seo?.metaTitle}
					metaDescription={seo?.metaDescription}
					metaImage={seo?.metaImage}
					metaSocial={seo?.metaSocial}
					canonicalURL={seo?.canonicalURL}
					keywords={seo?.keywords}
					metaRobots={seo?.metaRobots}
					metaViewport={seo?.metaViewport}
					structuredData={seo?.structuredData}
					breadCrumb={breadCrumb}
					mainEntityOfPage={articleSchema}
				/>
			}

			<Nav isBgWhite={true} />
			
			{insight && 	
				<>		
					<InsightBanner 
						typesList={insight?.type}
						date={insight?.publishedDate}
						title={insight?.title}
						picture={insight?.image?.url}
					/>

					<InsightContent>
						{insight?.elementsList?.map((element: any, i) =>{ 
							if(element?.name === 'individual-insight.paragraph'){
								return(
									<InsightText key={i}>
										<RichTextStylingCmp>
											<RichTextTransformCmp>
												{element?.paragraph}
											</RichTextTransformCmp>
										</RichTextStylingCmp>
									</InsightText>
								)
							}else if(element?.name === 'individual-insight.double-image'){
								return(
									<InsightPictures parentIndex={`${i}-parent-double-image`} key={`${i}-parent-double-image`} list={[
										{
											url: `${element?.image_one?.url}`
										},
										{
											url: `${element?.image_two?.url}`
										}
									]}/>
								)
							}else if(element?.name === 'individual-insight.single-image'){
								return(
									<InsightPictures parentIndex={`${i}-parent-single-image`} key={`${i}-parent-single-image`} list={[
										{
											url: `${element?.image?.url}`,
											fullScreen: true
										}
									]}/>
								)
							}
							return <></>
						})}
					</InsightContent>
				</>
			}

			{relatedInsightsList && relatedInsightsList?.length > 0 &&
				<RelatedInsight
					list={relatedInsightsList}
				/>
			}

			<GetStarted className="get-started-onInSight" />

			<Footer />
		</>
	);
}

export default Insight;