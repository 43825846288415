import { useTranslation } from 'react-i18next';

import ToolsListCmp from 'components/layout/toolsListCmp';

import { ToolsListFE } from 'types/SharedType';

import './SolutionTools.scss';
import CustomButton from 'components/common/customButton';

interface Props {
	label: string;
	list: ToolsListFE;
	canLoadMore: boolean;
	loadMore: () => void;
}

const SolutionTools = ({list, label, canLoadMore, loadMore}: Props) => {
	const {t} = useTranslation('solutions');

	return (
		<>
			{list && list?.length > 0 &&	
				<div className='solution-tools'>
					<ToolsListCmp
						tag={`${label} ${t('technologyTag')}`}
						title={t('technologyTitle')}
						list={list}
					/>
					{canLoadMore &&
						<div className='load-more-btn'>
							<CustomButton
								onClickBtn={() => loadMore()}
							>
								{t('loadMoreBtn')}
							</CustomButton>
						</div>
					}
				</div>
			}
		</>
	);
}

export default SolutionTools;