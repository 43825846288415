import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { NotificationContext } from "providers/notificationProvider";

import PageDataHandler from "utils/PageDataHandler";

import { PageInfo } from "types/SharedType";

import SeoComponent from "components/common/seoComponent/SeoComponent";
import HeroBanner from "components/layout/heroBanner";
import Nav from "components/layout/nav";
import Footer from "components/layout/footer";
import MainPoints from "components/layout/mainPoints";
import Introduction from "components/layout/introduction";
import ImageAccordian from "components/layout/imageAccordian";
import Expertise from "components/layout/expertise/Expertise";
import CyberSecurityOversight from "./components/cyberSecurityOversight";
// import CyberSecurityStrengths from "./components/cyberSecurityStrengths";
import CyberSecurityTestimonies from "./components/cyberSecurityTestimonies";
import CyberSecurityTailoredSolution from "./components/cyberSecurityTailoredSolution";

interface CyberSecurityPage {
	introduction?: {
		title: string;
		description: string;
	};
	mainPoints?: {
		title: string;
		description: string;
	}[];
	approach?: {
		tag: string;
		title: string;
		descriotion: string;
		image: string;
		imageCaption: string;
		accordianList: {
			title: string;
			description: string;
		}[];
	};
	expertise?: {
		tag: string;
		title: string;
		description: string;
	};
}

const handleExtraData = (extraData: any) => {
	return {
		introduction: {
			title: extraData?.introduction?.title,
			description: extraData?.introduction?.description,
		},
		mainPoints: extraData?.main_points?.map((point: any) => {
			return {
				title: point?.label,
				description: point?.description,
			};
		}),
		approach: {
			tag: extraData?.approach?.main_text?.tag,
			title: extraData?.approach?.main_text.title,
			descriotion: extraData?.approach?.main_text.description,
			image: `${process.env.REACT_APP_STRAPI_URL}${extraData?.approach?.image?.data?.attributes?.url}`,
			imageCaption: extraData?.approach?.image_caption,
			accordianList: extraData?.approach?.accordians.map((accordian: any) => {
				return {
					title: accordian?.label,
					description: accordian?.description,
				};
			}),
		},
		expertise: {
			tag: extraData?.expertise?.tag,
			title: extraData?.expertise?.title,
			description: extraData?.expertise?.description,
		},
	};
};

const CyberSecurity = () => {
	const { i18n, t } = useTranslation("cyberSecurity");
	const { displayNotification } = useContext(NotificationContext);

	const [pageInfo, setPageInfo] = useState<PageInfo>();
	const [cyberSecurityPage, setCyberSecurityPage] =
		useState<CyberSecurityPage>();

	const pageDataHandler = PageDataHandler();

	const getPage = async (pageName: string, locale: string) => {
		try {
			const pageInfo = await pageDataHandler.getPageInfo(pageName, locale);
			const pageData = handleExtraData(pageInfo?.pageData);

			pageInfo && setPageInfo(pageInfo);
			pageData && setCyberSecurityPage(pageData);
		} catch (error) {
			console.error(`[Error - API] Error handling Cyber Security data`, error);
			displayNotification(
				`Something Went Wrong handling Cyber Security Data, Please Try Again !`,
				"error"
			);
		}
	};

	useEffect(() => {
		getPage("cyber-security-page", i18n.language);
		// eslint-disable-next-line
	}, [i18n.language]);

	const breadCrumb = `{
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [
			{
				"@type": "ListItem",
				"position": 1,
				"item": {
						"@id": "${window.location.origin}",
						"name": "4i Tech: Home"
					}
			},
			{
				"@type": "ListItem",
				"position": 2,
				"item": {
						"@id": "${window.location.origin}/cyber-security",
						"name": "4i Tech: Cyber Security"
					}
			}
		]
	}`;

	const cyberSecuritySchema = `{
		"@type": "WebPage",
		"@id": "${window.location.href}",
		"url": "${window.location.href}",
		"name": "${pageInfo?.seo?.metaTitle}",
		"description": "${pageInfo?.seo?.metaDescription}",
		"inLanguage": "${i18n.language}",
		"about": {
			"@type": "Service",
			"name": "${pageInfo?.detail?.title}",
			"description": "${pageInfo?.detail?.title}",
			"provider": {
				"@type": "Organization",
				"name": "4i Tech",
				"url": "${window.location.origin}"
			}
		},
		"isPartOf":{
			"@type":"WebSite",
			"name":"4i Tech",
			"url":"${window.location.origin}"         
		}
	}`;
	
	return (
		<>
			<Nav />

			{pageInfo && (
				<SeoComponent
					metaTitle={pageInfo?.seo?.metaTitle}
					metaDescription={pageInfo?.seo?.metaDescription}
					metaImage={pageInfo?.seo?.metaImage}
					metaSocial={pageInfo?.seo?.metaSocial}
					canonicalURL={pageInfo?.seo?.canonicalURL}
					keywords={pageInfo?.seo?.keywords}
					metaRobots={pageInfo?.seo?.metaRobots}
					metaViewport={pageInfo?.seo?.metaViewport}
					structuredData={pageInfo?.seo?.structuredData}
					breadCrumb={breadCrumb}
					mainEntityOfPage={cyberSecuritySchema}
				/>
			)}

			<HeroBanner
				picture={pageInfo?.detail?.bannerImage?.url}
				title={pageInfo?.detail?.title}
				description={pageInfo?.detail?.description}
			/>

			{cyberSecurityPage && (
				<>
					{cyberSecurityPage.introduction && (
						<Introduction
							title={cyberSecurityPage?.introduction?.title}
							description={cyberSecurityPage?.introduction?.description}
						/>
					)}
					<MainPoints pointList={cyberSecurityPage?.mainPoints} />
					{cyberSecurityPage.approach && (
						<ImageAccordian
							tag={cyberSecurityPage?.approach?.tag}
							title={cyberSecurityPage?.approach?.title}
							description={cyberSecurityPage?.approach?.descriotion}
							image={cyberSecurityPage?.approach?.image}
							imageCaption={cyberSecurityPage?.approach?.imageCaption}
							accordianList={cyberSecurityPage?.approach?.accordianList}
							buttonText={t("projectManagmentBtn")}
						/>
					)}
					{cyberSecurityPage.expertise && (
						<Expertise
							tag={cyberSecurityPage?.expertise?.tag}
							title={cyberSecurityPage?.expertise?.title}
							description={cyberSecurityPage?.expertise?.description}
							buttonText={t("aboutUsBtn")}
						/>
					)}

					<CyberSecurityOversight />

					{/* <CyberSecurityStrengths /> */}

					<CyberSecurityTestimonies />

					<CyberSecurityTailoredSolution />
				</>
			)}

			<Footer />
		</>
	);
};

export default CyberSecurity;
