import { DoubleCircleOverlayStyles } from './SharedTypes';

import DoubleCircleImg from 'assets/img/doubleCircle2.svg';
import DoubleCircleGreyImg from 'assets/img/doubleCircleGrey.svg';

import './DoubleCircleOverlay.scss';

interface Props {
	size?: string;
	margin?: string;
	isCenter?: boolean;
	style?: string;
	className?: string;
}

const DoubleCircleOverlay = ({size = "auto", style = DoubleCircleOverlayStyles.WHITE, className}: Props) => {

	const getCircleImg = () => {
		let resu = '';
		switch (style) {
			case DoubleCircleOverlayStyles.WHITE:
				resu = DoubleCircleImg;
				break;
			case DoubleCircleOverlayStyles.GREY:
				resu = DoubleCircleGreyImg;
				break;
			default:
				resu = DoubleCircleImg;
		}

		return resu;
	}

	return (
		<div
			className={`double-circle-overlay ${className && className}`}
			style={{
				width: size,
				height: size,
				transform: 'translateZ(0)',
			}}
		>
				<img className='double-circle-img'
					alt='double circle background'
					src={getCircleImg()}
				/>
		</div>
	);
}

export default DoubleCircleOverlay;