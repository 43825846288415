import { useContext } from 'react';
import { NotificationContext } from 'providers/notificationProvider';

import axios from 'axios';
import { GetInTouchPayloadType } from 'components/common/getInTouchForm/GetInTouchTypes';

const GetInTouchFormApi = () => {

	const { displayNotification } = useContext(NotificationContext);

    const submitGetInTouchForm = async (formData: GetInTouchPayloadType) => {
        const result = await axios.post(process.env.REACT_APP_STRAPI_API_URL + `/get-in-touches`, 
				{
					data: formData
				})
        .then(response => {
            return response;
        }).catch(error => {
            displayNotification(`Something Went Wrong Fetching Case Study Data, Please Try Again !`, 'error');
            console.error(`[Error - API] Error calling Case Study data`, error);
        });
        return result;
    }

	return {
		submitGetInTouchForm: submitGetInTouchForm,
	};
}

export default GetInTouchFormApi;