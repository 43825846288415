import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import PageDataHandler from "utils/PageDataHandler";

import { NotificationContext } from "providers/notificationProvider";

import { PageInfo } from "types/SharedType";

import Nav from "components/layout/nav";
import Footer from "components/layout/footer";
import HeroBanner from "components/layout/heroBanner";
import EventsContainer from "./components/eventsContainer";
import EventsIncoming from "./components/eventsIncoming";
import SeoComponent from "components/common/seoComponent/SeoComponent";

const Events = () => {
	const { i18n } = useTranslation();
	const { displayNotification } = useContext(NotificationContext);

	const [pageInfo, setPageInfo] = useState<PageInfo>();

	const pageDataHandler = PageDataHandler();

	const getPageData = async(pageName: string, locale: string) => {
		try {
			
			const pageInfo: PageInfo | undefined = await pageDataHandler.getPageInfo(pageName, locale);

			pageInfo && setPageInfo(pageInfo);

		} catch (error) {
			console.error(`[Error - API] Error calling ${pageName} data`, error);
			displayNotification(`Something Went Wrong Fetching ${pageName} Data, Please Try Again !`, 'error');
		}
	}

	useEffect(() => {
		getPageData('events-page', i18n.language);
		// eslint-disable-next-line
	}, [i18n.language])

	return (
		<>
			<Nav />

			{pageInfo &&
				<>
					<SeoComponent
						metaTitle={pageInfo?.seo?.metaTitle}
						metaDescription={pageInfo?.seo?.metaDescription}
						metaImage={pageInfo?.seo?.metaImage}
						metaSocial={pageInfo?.seo?.metaSocial}
						canonicalURL={pageInfo?.seo?.canonicalURL}
						keywords={pageInfo?.seo?.keywords}
						metaRobots={pageInfo?.seo?.metaRobots}
						metaViewport={pageInfo?.seo?.metaViewport}
						structuredData={pageInfo?.seo?.structuredData}
					/>
				</>
			}

			<HeroBanner 
				picture={pageInfo?.detail?.bannerImage?.url}
				title={pageInfo?.detail?.title}
				description={pageInfo?.detail?.description}
			/>

			{pageInfo &&
				<EventsContainer>
					<EventsIncoming />
					
					{/* <EventsList list={eventsList} currentPage={currentPage}/> */}
				</EventsContainer>

			// :
			// 	<EventsNoEventBanner />
			}

			<Footer />
		</>
	);
}

export default Events;