import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


import { BtnStyles } from 'components/common/customButton/SharedTypes';

import PageWrapper from 'components/common/pageWrapper';
import CustomButton from 'components/common/customButton';
import Clients from 'components/layout/clients';

import './MainClients.scss';

const MainClients = () => {
	const { t } = useTranslation('homepage');
	const navigate = useNavigate();

	const [isEmpty, setIsEmpty] = useState<boolean>(true);

	const onFetch = (isEmpty:boolean) => {
		setIsEmpty(isEmpty);
	}
	
	return (
		<div className={`main-clients ${!isEmpty && 'show'}`}>
			<PageWrapper className='top-bar'>
				<div className='title'>
					{t("clientsTitle")}
				</div>

				<div className='link-clients-btn'>
					<CustomButton
						onClickBtn={() => navigate('/our-clients')}
						btnStyle={BtnStyles.TERTIARY}
					>
						{t('clientBtnLabel')}
					</CustomButton>
				</div>
			</PageWrapper>

			<Clients filter='NoPagination' isCarousel={true} onFetch={onFetch}/>
		</div>
	);
}

export default MainClients;