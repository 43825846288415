import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import FullWidth from 'components/common/fullWidth';
import CustomButton from 'components/common/customButton';

import TeamImg from 'assets/img/team2.webp';

import './WhitePaperGetStarted.scss';

const WhitePaperGetStarted = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('whitePaper');

	return (
		<FullWidth>
			<div className='white-paper-get-started'>
				<div className='left-column'>
					<div className='title'>
						{t('anyQuestionTitle')}
					</div>

					<div className='description'>
						{t('anyQuestionDescription')}
					</div>

					<CustomButton
						onClickBtn={() => navigate('/contact-us')}
					>
						{t('anyQuestionBtn')}
					</CustomButton>
				</div>

				<div className='right-column'>
					<div className='team-image' style={{backgroundImage: `url(${TeamImg})`}}></div>
				</div>
			</div>
		</FullWidth>
	);
}

export default WhitePaperGetStarted;