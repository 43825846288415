import { useContext } from 'react';
import { NotificationContext } from 'providers/notificationProvider';

import { NewsletterSubscriberType } from 'types/SharedType';

import axios from 'axios';

const NewsletterSubscriberApi = () => {

    const { displayNotification } = useContext(NotificationContext);

		const getSubscriberinfo = async(locale?: string, user_email?: string, id?: number) => {

			let param = '';

			if(user_email !== ''){
				param = `?filters[user_email]=${user_email}&locale=${locale}`;
			}else if(id){
				param = `/${id}`;
			}
			const result = await axios.get(process.env.REACT_APP_STRAPI_API_URL + `/newsletter-subscribers${param}`)
			.then(response => {
				return response?.data?.data;
			}).catch(error => {
				console.error("[Error - API] Error calling getSubscriberinfo", error);
			});

			return result;
		}

    const createSubscriberEntry = async (formData: NewsletterSubscriberType) => {
			const response = await axios.post(`${process.env.REACT_APP_STRAPI_API_URL}/newsletter-subscribers`, {
				data: formData,
			}).then(response => {
				return response?.request?.status;
			}).catch(error => {
				displayNotification('Subscription request failed ! Please, try again.', 'error');
				console.error("[Error - API] Error calling createSubscriberEntry", error);
				return error;
			});
			return response;
    }

		const updateSubscriberConfimationStatus = async(id: number, updateTo: boolean) => {
			const response = await axios.put(`${process.env.REACT_APP_STRAPI_API_URL}/newsletter-subscribers/${id}`, {
				data: {
					confirmed: updateTo,
				},
			}).then(response => {
				return response?.request?.status;
			}).catch(error => {
				console.error("[Error - API] Error calling updateSubscriberConfimationStatus", error);
			});

			return response;
		}

    return {
			createSubscriberEntry: createSubscriberEntry,
			getSubscriberinfo: getSubscriberinfo,
			updateSubscriberConfimationStatus: updateSubscriberConfimationStatus,
    };
}

export default NewsletterSubscriberApi;