import { useContext } from "react";
import { NotificationContext } from "providers/notificationProvider";

import axios from "axios";

const CommonPageApi = () => {
	const { displayNotification } = useContext(NotificationContext);

	const getPageData = async (pageName: string, locale: string) => {
		/* some pages will have default + static yet unique populate fields
		for that we use the pageName to determine additional param values */
		let params = "";

		if (pageName === "insights-page") {
			params =
				"selected_insights.insights.image,selected_insights.insights.elements,selected_insights.insights.insight_types";
		}

		if (pageName === "clients-page") {
			params = "selected_case_studies.case_studies.banner_image";
		}

		if (pageName === "project-management-page") {
			params = "tools.Logo";
		}
		if (pageName === "cyber-security-page") {
			params =
				"introduction,main_points,approach.main_text,approach.image,approach.accordians,expertise";
		}

		const result = await axios
			.get(
				process.env.REACT_APP_STRAPI_API_URL +
					`/${pageName}?locale=${locale}&populate=seo.metaImage,seo.metaSocial.image,detail.banner_image,${params}`
			)
			.then((response) => {
				return response?.data?.data?.attributes;
			})
			.catch((error) => {
				displayNotification(
					`Something Went Wrong Fetching ${pageName} Data, Please Try Again !`,
					"error"
				);
				console.error(`[Error - API] Error calling ${pageName} data`, error);
			});

		return result;
	};

	return {
		getPageData: getPageData,
	};
};

export default CommonPageApi;
