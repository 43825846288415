import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

import Homepage from "components/pages/homepage";
import Solutions from "components/pages/solutions";
import Solution from "components/pages/solution";
import AboutUs from "components/pages/aboutUs";
import WhitePaper from "components/pages/whitePaper";
import OurClients from "components/pages/ourClients";
import CaseStudies from "components/pages/caseStudies";
import CaseStudy from "components/pages/caseStudy";
import Insights from "components/pages/insights";
import Insight from "components/pages/insight";
import ProjectManagement from "components/pages/projectManagement";
import Events from "components/pages/events";
import Event from "components/pages/event";
import ContactUs from "components/pages/contactUs";
// import TermsAndConditions from "components/pages/termsAndConditions";
import PrivacyPolicy from "components/pages/privacyPolicy";
// import WelcomePopUp from "components/layout/welcomePopUp";
import ScrollToTop from "./ScrollToTop";
import SubscriberConfirmation from "components/pages/subscriberConfirmation/SubscriberConfirmation";
import WhitePaperInquiryNotice from "components/pages/whitePaperInqiuryNotice/WhitePaperInquiryNotice";
import ContactUsInquiryNotice from "components/pages/contactUsInquiryNotice/contactUsInquiryNotice";
import CookiePolicy from "components/pages/cookiePolicy";
import CookiePopUp from "components/layout/cookiePopUp";
import ScrollToTopButton from "components/layout/ScrollToTopButton/ScrollToTopButton";
import CyberSecurity from "components/pages/cyberSecurity";
import Page404 from "components/pages/page404";

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID
	? process.env.REACT_APP_GA_TRACKING_ID
	: null;
if (TRACKING_ID) ReactGA.initialize(TRACKING_ID);

const App = () => {
	const { i18n } = useTranslation();
	const location = useLocation();
	// const currentPath = location.pathname;

	// const setLanguage = () => {

	// 	const lang = localStorage.getItem('lang');
	// 	if(lang){
	// 		i18n.changeLanguage(lang);
	// 	}
	// }

	// useEffect(() => {
	// 	setLanguage();
	// 	// eslint-disable-next-line
	// }, []);

	useEffect(() => {
		if (TRACKING_ID)
			ReactGA.send({
				hitType: "pageview",
				page: location.pathname + location.search,
				title: location.pathname,
			});
	}, [location]);

	useEffect(() => {
		document.documentElement.lang = i18n.language;
	}, [i18n]);

	return (
		<>
			<div className="App">
				{/* <WelcomePopUp /> */}
				<Helmet>
					<meta name="seobility" content="5dff6958ae299379e46340a042cd0999" />
				</Helmet>

				<CookiePopUp />
				<ScrollToTop />
				<Routes>
					<Route path="/" element={<Homepage />} />
					<Route path="/about-us" element={<AboutUs />} />
					<Route path="/project-management" element={<ProjectManagement />} />
					<Route path="/cyber-security" element={<CyberSecurity />} />
					<Route path="/solutions" element={<Solutions />} />
					<Route path="/solution/:id" element={<Solution />} />
					<Route path="/white-paper/:id" element={<WhitePaper />} />
					<Route
						path="/white-paper/inquiry"
						element={<WhitePaperInquiryNotice />}
					/>
					<Route path="/case-studies" element={<CaseStudies />} />
					<Route path="/case-study/:id" element={<CaseStudy />} />
					<Route path="/insights" element={<Insights />} />
					<Route path="/insight/:id" element={<Insight />} />
					<Route path="/our-clients" element={<OurClients />} />
					<Route path="/events" element={<Events />} />
					<Route path="/event/:id" element={<Event />} />
					<Route path="/contact-us" element={<ContactUs />} />
					<Route
						path="/contact-us/inquiry"
						element={<ContactUsInquiryNotice />}
					/>
					{/* <Route
						path="/terms-and-conditions"
						element={<TermsAndConditions />}
					/> */}
					<Route path="/privacy-policy" element={<PrivacyPolicy />} />
					<Route path="/cookie-policy" element={<CookiePolicy />} />
					<Route
						path="/confirmation/:id"
						element={<SubscriberConfirmation />}
					/>
					<Route path="*" element={<Page404 />} />
				</Routes>
				<ScrollToTopButton />
			</div>
		</>
	);
};

export default App;
