import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationContext } from 'providers/notificationProvider';

import PageDataHandler from 'utils/PageDataHandler';

import { PageInfo } from 'types/SharedType';

import Nav from 'components/layout/nav';
import Footer from 'components/layout/footer';
import HeroBanner from 'components/layout/heroBanner';

import CaseStudiesList from './components/caseStudiesList';
import CaseStudiesTestimonials from './components/caseStudiesTestimonials';
import SeoComponent from 'components/common/seoComponent/SeoComponent';

const CaseStudies = () => {
	
	const { i18n } = useTranslation();
	const { displayNotification } = useContext(NotificationContext);

	const [pageInfo, setPageInfo] = useState<PageInfo>();

	const pageDataHandler = PageDataHandler();

	const getPageData = async(pageName: string, locale: string) => {
		try {
			
			const pageInfo = await pageDataHandler.getPageInfo(pageName, locale);

			pageInfo && setPageInfo(pageInfo);

		} catch (error) {
			console.error(`[Error - API] Error calling ${pageName} data`, error);
			displayNotification(`Something Went Wrong Fetching ${pageName} Data, Please Try Again !`, 'error');
		}
	}

	useEffect(() => {
		getPageData('case-studies-page', i18n.language);
		// eslint-disable-next-line
	}, [i18n.language])

	const breadCrumb = `{
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [
			{
				"@type": "ListItem",
				"position": 1,
				"item": {
						"@id": "${window.location.origin}",
						"name": "4i Tech: Home"
					}
			},
			{
				"@type": "ListItem",
				"position": 2,
				"item": {
						"@id": "${window.location.href}",
						"name": "4i Tech: Case Studies"
					}
			}
		]
	}`;

	const caseStudySchema = `{
		"@type": "WebPage",
		"@id": "${window.location.href}",
		"url": "${window.location.href}",
		"name": "${pageInfo?.seo?.metaTitle}",
		"description":"${pageInfo?.seo?.metaDescription}",
		"inLanguage": "${i18n.language}",
		"isPartOf":{
			"@type":"WebSite",
			"name":"4i Tech",
			"url":"${window.location.origin}"         
		}
	}`;

	return (
		<>
			<Nav />
			
			{pageInfo &&
				<>
					<SeoComponent
						metaTitle={pageInfo?.seo?.metaTitle}
						metaDescription={pageInfo?.seo?.metaDescription}
						metaImage={pageInfo?.seo?.metaImage}
						metaSocial={pageInfo?.seo?.metaSocial}
						canonicalURL={pageInfo?.seo?.canonicalURL}
						keywords={pageInfo?.seo?.keywords}
						metaRobots={pageInfo?.seo?.metaRobots}
						metaViewport={pageInfo?.seo?.metaViewport}
						structuredData={pageInfo?.seo?.structuredData}
						breadCrumb={breadCrumb}
						mainEntityOfPage={caseStudySchema}
					/>
					
				</>
			}

			<HeroBanner 
				picture={pageInfo?.detail?.bannerImage?.url}
				title={pageInfo?.detail?.title}
				description={pageInfo?.detail?.description}
			/>

			<CaseStudiesList />

			<CaseStudiesTestimonials />

			<Footer />
		</>
	);
}
  
export default CaseStudies;