import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import HistoricListCmp from 'components/layout/historicListCmp';

import { NotificationContext } from 'providers/notificationProvider';

import CompanyMileStonesApi from 'api/CompanyHistoryApi';

import { DevelopmentStepTypeFE } from 'types/SharedType';

import "./OurHistory.scss";

interface CompanyHistoryBE {
	attributes: {
		date: string;
		title: string;
		description: string;
	}
	id: number;
}

const handleCompanyMileStonesData = (companyHistoryListData: CompanyHistoryBE[]) => {
	return companyHistoryListData?.map((history) => {
		return {
			tag: new Date(history?.attributes?.date)?.getFullYear()?.toString(),
			title: history?.attributes?.title,
			description: history?.attributes?.description
		}
	})
}

const OurHistory = () => {
	const {t, i18n} = useTranslation('aboutUs');
	const { displayNotification } = useContext(NotificationContext);

	const [companyMileStoneList, setCompanyMileStoneList] = useState<DevelopmentStepTypeFE[]>();

	const companyMileStonesApi = CompanyMileStonesApi();

	const getCompanyHistory = async(locale: string) => {
		try {

			const companyMileStonesData: CompanyHistoryBE[] = await companyMileStonesApi?.getCompanyMileStonesData(locale);

			const companyHistoryList = handleCompanyMileStonesData(companyMileStonesData);

			companyHistoryList && setCompanyMileStoneList(companyHistoryList);
			
		} catch (error) {
			console.log(error);
			displayNotification('Something Went Wrong When Handling History List Data, Please Try Again !', 'error');
		}
	}

	useEffect(() => {
		getCompanyHistory(i18n.language);
		// eslint-disable-next-line
	}, [i18n.language])

	return (
		<>		
			{companyMileStoneList && companyMileStoneList?.length > 0 && 
				<div className='our-history'>
					<HistoricListCmp
						tag={t('historyTag')}
						title={t('historyTitle')}
						description={t('historyDescription')}
						list={companyMileStoneList}
					/>
				</div>
			}
		</>
	);
}

export default OurHistory;