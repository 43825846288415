import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import EventApi from "api/EventApi";

import { ImageFE, PageDetailBE, PageDetailFE, SeoBE, SeoFE } from "types/SharedType";

import { NotificationContext } from "providers/notificationProvider";

import SeoDataHandler from "utils/SeoDataHandler";
import DetailDataHandler from "utils/DetailDataHandler";

import Nav from "components/layout/nav";
import Footer from "components/layout/footer";
import EventBanner from "./components/eventBanner";
import EventDetails from "./components/eventDetails";
import EventRegister from "./components/eventRegister";
import SeoComponent from "components/common/seoComponent/SeoComponent";

interface EventDataBE {
	seo: SeoBE;
	detail: PageDetailBE;
	date_end: Date;
	date_start: Date;
	location: string;
	form_id: string;
	image_list: {
		data: {
			attributes:{
				url: string;
				caption: string;
				alternativeText: string;
			}
		}[]
	};
	event_types: {
		data: {
			attributes: {
				name: string;
			}
		}[]
	};
	locale: string;
	localizations: {
		data: {
			id: number;
			attributes: {
				locale: string;
			}
		}[]
	}
}

interface EventDataFE {
	dateEnd: Date;
	dateStart: Date;
	location: string;
	formId: string;
	imageList: ImageFE[];
	eventTypeList: string[];
	locale: string;
	localizations: {
		attributes: {
			locale: string;
		}
		id: number;
	}[]
}

const handleEventData = (eventData: EventDataBE) => {
	return {
		dateEnd: eventData?.date_end,
		dateStart: eventData?.date_start,
		location: eventData?.location,
		formId: eventData?.form_id,
		imageList: eventData?.image_list?.data?.map(image =>{
			return{
				url: `${process.env.REACT_APP_STRAPI_URL}${image?.attributes?.url}`,
				caption: image?.attributes?.caption,
				alternativeText: image?.attributes?.alternativeText,
			}
		}),
		eventTypeList: eventData?.event_types?.data?.map((element) => element?.attributes?.name),
		locale: eventData?.locale,
		localizations: eventData?.localizations?.data,
	}
}

const Event = () => {

	const {id} = useParams();
	const { i18n } = useTranslation();
	const { displayNotification } = useContext(NotificationContext);

	const [seo, setSEO] = useState<SeoFE>();
	const [pageDetail, setPageDetail] = useState<PageDetailFE>();
	const [eventDetail, setEventDetail] = useState<EventDataFE>();

	const registerRef = useRef<HTMLDivElement | null>(null);

	const eventApi = EventApi();
	const seoDataHandler = SeoDataHandler();
	const detailDataHandler = DetailDataHandler();

	const getEvent = async() => {
		try {
			if(id){
				const	eventData: EventDataBE  = await eventApi.getEventData(id, i18n.language);

				const seo: SeoFE = seoDataHandler.handleSeoData(eventData?.seo);
				const detail: PageDetailFE = detailDataHandler.handleDetailData(eventData?.detail);
				const eventDetail: EventDataFE = handleEventData(eventData);

				seo && setSEO(seo);
				detail && setPageDetail(detail);
				eventDetail && setEventDetail(eventDetail);			
			}

		} catch (error) {
			console.error(`[Error - API] Error calling Event data`, error);
			displayNotification(`Something Went Wrong Fetching Event Data, Please Try Again !`, 'error');
		}
	}

	const registerClickHandler = () => {
		if (registerRef.current) {
			const offsetTop = registerRef.current.offsetTop;
			const navHeight = document.getElementsByClassName('main-nav ')[0].clientHeight;
			const scrollTo = offsetTop - navHeight;
            window.scrollBy({ top: scrollTo, behavior: 'smooth'});
		}
	}

	useEffect(() => {
		getEvent();
		// eslint-disable-next-line
	}, [id, i18n.language])

	return (
		<>

			{seo &&
				<SeoComponent
					metaTitle={seo?.metaTitle}
					metaDescription={seo?.metaDescription}
					metaImage={seo?.metaImage}
					metaSocial={seo?.metaSocial}
					canonicalURL={seo?.canonicalURL}
					keywords={seo?.keywords}
					metaRobots={seo?.metaRobots}
					metaViewport={seo?.metaViewport}
					structuredData={seo?.structuredData}
				/>
			}

			<Nav />

			{pageDetail && eventDetail &&
				<>
					<EventBanner
						id={(id ? id: '')}
						picture={pageDetail?.bannerImage?.url}
						title= {pageDetail?.title}
						categoryList={eventDetail?.eventTypeList}
						startDate={eventDetail?.dateStart}
						endDate={eventDetail?.dateEnd}
						place={eventDetail?.location}
						onClickRegister={registerClickHandler}
						/>

					<EventDetails 
						description={pageDetail?.description}
						pictureList={eventDetail?.imageList?.map(image => image?.url)}
						/>

					<div className='event-register-ref' ref={registerRef}>
						<EventRegister 
							formId={eventDetail?.formId}
						/>
					</div>
				</>
			}
			
			<Footer />
		</>
	);
}

export default Event;