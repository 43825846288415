import { useContext } from 'react';
import { NotificationContext } from 'providers/notificationProvider';

import axios from 'axios';

const CompanyMileStonesApi = () => {

	const { displayNotification } = useContext(NotificationContext);

	const getCompanyMileStonesData = async(locale: string) => {
		const result = await axios.get(process.env.REACT_APP_STRAPI_API_URL + `/company-histories?locale=${locale}&sort=rank`)
		.then(response => {
				return response?.data?.data;
		}).catch(error => {
				displayNotification(`Something Went Wrong Fetching History List Data, Please Try Again !`, 'error');
				console.error(`[Error - API] Error calling Insight data`, error);
		});
		return result;
	}

	return {
		getCompanyMileStonesData: getCompanyMileStonesData,
	};
}

export default CompanyMileStonesApi;