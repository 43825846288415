import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { HelmetProvider } from "react-helmet-async";
import i18n from "i18next";

import translationNavEN from "intl/en/nav.json";
import translationHomepageEN from "intl/en/homepage.json";
import translationGetInTouchEN from "intl/en/getInTouch.json";
import translationFooterEN from "intl/en/footer.json";
import translationSolutionsEN from "intl/en/solutions.json";
import translationContactUsEN from "intl/en/contactUs.json";
import translationWhitePaperEN from "intl/en/whitePaper.json";
import translationOurClientEN from "intl/en/ourClients.json";
import translationCasesStudiesEN from "intl/en/casesStudies.json";
import translationInsightsEN from "intl/en/insights.json";
import translationAboutUsEN from "intl/en/aboutUs.json";
import translationProjectManagementEN from "intl/en/projectManagement.json";
import translationEventsEN from "intl/en/events.json";
import translationTermsAndConditionsEN from "intl/en/termsAndConditions.json";
import translationPrivacyPolicyEN from "intl/en/privacyPolicy.json";
import translationWelcomeEN from "intl/en/welcome.json";
import translationOfficesEN from "intl/en/offices.json";
import translationCookiePolicyEN from "intl/en/cookiePolicy.json";
import translationThankYouEN from "intl/en/thankYou.json";
import subscriberConfirmationEN from "intl/en/subscriberConfirmation.json";
import translationCyberSecurityEN from "intl/en/cyberSecurity.json";

import translationNavZH from "intl/zh/nav.json";
import translationHomepageZH from "intl/zh/homepage.json";
import translationGetInTouchZH from "intl/zh/getInTouch.json";
import translationFooterZH from "intl/zh/footer.json";
import translationSolutionsZH from "intl/zh/solutions.json";
import translationContactUsZH from "intl/zh/contactUs.json";
import translationWhitePaperZH from "intl/zh/whitePaper.json";
import translationOurClientZH from "intl/zh/ourClients.json";
import translationCasesStudiesZH from "intl/zh/casesStudies.json";
import translationInsightsZH from "intl/zh/insights.json";
import translationAboutUsZH from "intl/zh/aboutUs.json";
import translationProjectManagementZH from "intl/zh/projectManagement.json";
import translationEventsZH from "intl/zh/events.json";
import translationTermsAndConditionsZH from "intl/zh/termsAndConditions.json";
import translationPrivacyPolicyZH from "intl/zh/privacyPolicy.json";
import translationWelcomeZH from "intl/zh/welcome.json";
import translationOfficesZH from "intl/zh/offices.json";
import translationCookiePolicyZH from "intl/zh/cookiePolicy.json";
import translationThankYouZH from "intl/zh/thankYou.json";
import subscriberConfirmationZH from "intl/zh/subscriberConfirmation.json";
import translationCyberSecurityZH from "intl/zh/cyberSecurity.json";

import translationNavDE from "intl/de/nav.json";
import translationHomepageDE from "intl/de/homepage.json";
import translationGetInTouchDE from "intl/de/getInTouch.json";
import translationFooterDE from "intl/de/footer.json";
import translationSolutionsDE from "intl/de/solutions.json";
import translationContactUsDE from "intl/de/contactUs.json";
import translationWhitePaperDE from "intl/de/whitePaper.json";
import translationOurClientDE from "intl/de/ourClients.json";
import translationCasesStudiesDE from "intl/de/casesStudies.json";
import translationInsightsDE from "intl/de/insights.json";
import translationAboutUsDE from "intl/de/aboutUs.json";
import translationProjectManagementDE from "intl/de/projectManagement.json";
import translationEventsDE from "intl/de/events.json";
import translationTermsAndConditionsDE from "intl/de/termsAndConditions.json";
import translationPrivacyPolicyDE from "intl/de/privacyPolicy.json";
import translationWelcomeDE from "intl/de/welcome.json";
import translationOfficesDE from "intl/de/offices.json";
import translationCookiePolicyDE from "intl/de/cookiePolicy.json";
import translationThankYouDE from "intl/de/thankYou.json";
import subscriberConfirmationDE from "intl/de/subscriberConfirmation.json";
import translationCyberSecurityDE from "intl/de/cyberSecurity.json";

import translationNavFR from "intl/fr/nav.json";
import translationHomepageFR from "intl/fr/homepage.json";
import translationGetInTouchFR from "intl/fr/getInTouch.json";
import translationFooterFR from "intl/fr/footer.json";
import translationSolutionsFR from "intl/fr/solutions.json";
import translationContactUsFR from "intl/fr/contactUs.json";
import translationWhitePaperFR from "intl/fr/whitePaper.json";
import translationOurClientFR from "intl/fr/ourClients.json";
import translationCasesStudiesFR from "intl/fr/casesStudies.json";
import translationInsightsFR from "intl/fr/insights.json";
import translationAboutUsFR from "intl/fr/aboutUs.json";
import translationProjectManagementFR from "intl/fr/projectManagement.json";
import translationEventsFR from "intl/fr/events.json";
import translationTermsAndConditionsFR from "intl/fr/termsAndConditions.json";
import translationPrivacyPolicyFR from "intl/fr/privacyPolicy.json";
import translationWelcomeFR from "intl/fr/welcome.json";
import translationOfficesFR from "intl/fr/offices.json";
import translationCookiePolicyFR from "intl/fr/cookiePolicy.json";
import translationThankYouFR from "intl/fr/thankYou.json";
import subscriberConfirmationFR from "intl/fr/subscriberConfirmation.json";
import translationCyberSecurityFR from "intl/fr/cyberSecurity.json";

import translationNavJP from "intl/jp/nav.json";
import translationHomepageJP from "intl/jp/homepage.json";
import translationGetInTouchJP from "intl/jp/getInTouch.json";
import translationFooterJP from "intl/jp/footer.json";
import translationSolutionsJP from "intl/jp/solutions.json";
import translationContactUsJP from "intl/jp/contactUs.json";
import translationWhitePaperJP from "intl/jp/whitePaper.json";
import translationOurClientJP from "intl/jp/ourClients.json";
import translationCasesStudiesJP from "intl/jp/casesStudies.json";
import translationInsightsJP from "intl/jp/insights.json";
import translationAboutUsJP from "intl/jp/aboutUs.json";
import translationProjectManagementJP from "intl/jp/projectManagement.json";
import translationEventsJP from "intl/jp/events.json";
import translationTermsAndConditionsJP from "intl/jp/termsAndConditions.json";
import translationPrivacyPolicyJP from "intl/jp/privacyPolicy.json";
import translationWelcomeJP from "intl/jp/welcome.json";
import translationOfficesJP from "intl/jp/offices.json";
import translationCookiePolicyJP from "intl/jp/cookiePolicy.json";
import translationThankYouJP from "intl/jp/thankYou.json";
import subscriberConfirmationJP from "intl/jp/subscriberConfirmation.json";
import translationCyberSecurityJP from "intl/jp/cyberSecurity.json";

import { NotificationProvider } from "providers/notificationProvider";
import { HomeDataProvider } from "providers/homeDataProvider/HomeDataProvider";
import { NumbersDataProvider } from "providers/numberDataProvider/NumberDataProvider";
import { ClientIndustryListProvider } from "providers/clientsTypeProvider/ClientsTypeProvider";
import { TestimonialProvider } from "providers/testimonialDataProvider/testimonialProvider";
import { SolutionsListProvider } from "providers/solutionsListProvider/SolutionsListProvider";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./styles/main.scss";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

const resources = {
	en: {
		nav: translationNavEN,
		homepage: translationHomepageEN,
		getInTouch: translationGetInTouchEN,
		footer: translationFooterEN,
		solutions: translationSolutionsEN,
		contactUs: translationContactUsEN,
		whitePaper: translationWhitePaperEN,
		ourClients: translationOurClientEN,
		caseStudies: translationCasesStudiesEN,
		insights: translationInsightsEN,
		aboutUs: translationAboutUsEN,
		projectManagement: translationProjectManagementEN,
		events: translationEventsEN,
		termsAndConditions: translationTermsAndConditionsEN,
		privacyPolicy: translationPrivacyPolicyEN,
		welcome: translationWelcomeEN,
		offices: translationOfficesEN,
		cookiePolicy: translationCookiePolicyEN,
		thankYou: translationThankYouEN,
		confirmation: subscriberConfirmationEN,
		cyberSecurity: translationCyberSecurityEN,
	},
	zh: {
		nav: translationNavZH,
		homepage: translationHomepageZH,
		getInTouch: translationGetInTouchZH,
		footer: translationFooterZH,
		solutions: translationSolutionsZH,
		contactUs: translationContactUsZH,
		whitePaper: translationWhitePaperZH,
		ourClients: translationOurClientZH,
		caseStudies: translationCasesStudiesZH,
		insights: translationInsightsZH,
		aboutUs: translationAboutUsZH,
		projectManagement: translationProjectManagementZH,
		events: translationEventsZH,
		termsAndConditions: translationTermsAndConditionsZH,
		privacyPolicy: translationPrivacyPolicyZH,
		welcome: translationWelcomeZH,
		offices: translationOfficesZH,
		cookiePolicy: translationCookiePolicyZH,
		thankYou: translationThankYouZH,
		confirmation: subscriberConfirmationZH,
		cyberSecurity: translationCyberSecurityZH,
	},
	de: {
		nav: translationNavDE,
		homepage: translationHomepageDE,
		getInTouch: translationGetInTouchDE,
		footer: translationFooterDE,
		solutions: translationSolutionsDE,
		contactUs: translationContactUsDE,
		whitePaper: translationWhitePaperDE,
		ourClients: translationOurClientDE,
		caseStudies: translationCasesStudiesDE,
		insights: translationInsightsDE,
		aboutUs: translationAboutUsDE,
		projectManagement: translationProjectManagementDE,
		events: translationEventsDE,
		termsAndConditions: translationTermsAndConditionsDE,
		privacyPolicy: translationPrivacyPolicyDE,
		welcome: translationWelcomeDE,
		offices: translationOfficesDE,
		cookiePolicy: translationCookiePolicyDE,
		thankYou: translationThankYouDE,
		confirmation: subscriberConfirmationDE,
		cyberSecurity: translationCyberSecurityDE,
	},
	fr: {
		nav: translationNavFR,
		homepage: translationHomepageFR,
		getInTouch: translationGetInTouchFR,
		footer: translationFooterFR,
		solutions: translationSolutionsFR,
		contactUs: translationContactUsFR,
		whitePaper: translationWhitePaperFR,
		ourClients: translationOurClientFR,
		caseStudies: translationCasesStudiesFR,
		insights: translationInsightsFR,
		aboutUs: translationAboutUsFR,
		projectManagement: translationProjectManagementFR,
		events: translationEventsFR,
		termsAndConditions: translationTermsAndConditionsFR,
		privacyPolicy: translationPrivacyPolicyFR,
		welcome: translationWelcomeFR,
		offices: translationOfficesFR,
		cookiePolicy: translationCookiePolicyFR,
		thankYou: translationThankYouFR,
		confirmation: subscriberConfirmationFR,
		cyberSecurity: translationCyberSecurityFR,
	},
	ja: {
		nav: translationNavJP,
		homepage: translationHomepageJP,
		getInTouch: translationGetInTouchJP,
		footer: translationFooterJP,
		solutions: translationSolutionsJP,
		contactUs: translationContactUsJP,
		whitePaper: translationWhitePaperJP,
		ourClients: translationOurClientJP,
		caseStudies: translationCasesStudiesJP,
		insights: translationInsightsJP,
		aboutUs: translationAboutUsJP,
		projectManagement: translationProjectManagementJP,
		events: translationEventsJP,
		termsAndConditions: translationTermsAndConditionsJP,
		privacyPolicy: translationPrivacyPolicyJP,
		welcome: translationWelcomeJP,
		offices: translationOfficesJP,
		cookiePolicy: translationCookiePolicyJP,
		thankYou: translationThankYouJP,
		confirmation: subscriberConfirmationJP,
		cyberSecurity: translationCyberSecurityJP,
	},
};

i18n.use(initReactI18next).init({
	resources,
	lng: process.env.REACT_APP_DEFAULT_LOCALE,
	fallbackLng: "en",
	interpolation: {
		escapeValue: false,
	},
});

root.render(
	<BrowserRouter>
		<I18nextProvider i18n={i18n}>
			<NotificationProvider>
				<HelmetProvider>
					<HomeDataProvider>
						<NumbersDataProvider>
							<ClientIndustryListProvider>
								<TestimonialProvider>
									<SolutionsListProvider>
										<App />
									</SolutionsListProvider>
								</TestimonialProvider>
							</ClientIndustryListProvider>
						</NumbersDataProvider>
					</HomeDataProvider>
				</HelmetProvider>
			</NotificationProvider>
		</I18nextProvider>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
