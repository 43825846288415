import { InsightDataTypeBE } from "components/pages/insights/components/listInsights/SharedType";

const InsightsListHandler = () => {

	const handleInsightList = (insightsData: InsightDataTypeBE[]) => {
		
		const insightList = insightsData?.map(insight => {
			return{
				urlPath: insight?.attributes?.url_path,
				type: insight?.attributes?.insight_types?.data?.map(type => type?.attributes?.type),
				title: insight?.attributes?.title,
				paragraph: insight?.attributes?.elements.find(element => element?.__component === 'individual-insight.paragraph')?.paragraph,
				publishedDate: insight?.attributes?.published
			}
		})

		return insightList;
	}

	return{
		handleInsightList: handleInsightList,
	}

}

export default InsightsListHandler