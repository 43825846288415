import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CarouselCmp from 'components/common/carouselCmp';
import CustomButton from 'components/common/customButton';

import { CaseStudyBannerFE } from 'types/SharedType';

import './SolutionCaseStudies.scss';

interface Props {
	list: CaseStudyBannerFE[];
}

const SolutionCaseStudies = ({list}: Props) => {
	const {t} = useTranslation('solutions');
	const navigate = useNavigate();
	
	const goToItem = (id: string) => {
		navigate(`/case-study/${id}`);
	}

	return (
		<>
			{list && list?.length > 0 &&			
				<div className='solution-case-studies'>
					<CarouselCmp
						title={t('caseStudiesCmpTitle')}
						description={t('caseStudiesCmpDescription') as string}
						listItem={list}
						onClickFunc={goToItem}
						extraContent={
							<CustomButton
								onClickBtn={() => navigate('/case-studies')}
							>
								{t('caseStudiesCmpBtn')}
							</CustomButton>
						}
					/>
				</div>
			}		
		</>
	);
}

export default SolutionCaseStudies;