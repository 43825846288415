import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import FullWidth from "components/common/fullWidth";
import CustomButton from "components/common/customButton";

import "./CyberSecurityTailoredSolution.scss";

const CyberSecurityTailoredSolution = () => {
	const { t } = useTranslation("cyberSecurity");
	const navigate = useNavigate();

	return (
		<div className="cyber-security-tailored-solutions">
			<FullWidth>
				<div className="cyber-security-tailored-solution-container">
					<div className="left-column">
						<div className="title">{t("tailoredSolutionsTitle")}</div>
					</div>

					<div className="right-column">
						<div className="description">
							{t("tailoredSolutionDescription")}
						</div>

						<CustomButton onClickBtn={() => navigate("/contact-us")}>
							{t("contactUsBtn")}
						</CustomButton>
					</div>
				</div>
			</FullWidth>
		</div>
	);
};

export default CyberSecurityTailoredSolution;
