import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { NotificationContext } from "providers/notificationProvider";

import NewsletterSubscriberApi from "api/NewsletterSubscriberApi";

import Nav from 'components/layout/nav';
import Notice from 'components/layout/notice/Notice';

const SubscriberConfirmation = () => {

	const navigate = useNavigate();
	const {id} = useParams();
	const { t } = useTranslation('confirmation');
	const { displayNotification } = useContext(NotificationContext);

	const [statusText, setStatusText] = useState<string>('Loading...');
	const [isSuccess, setIsSuccess] = useState<boolean>(false);

	const newsletterSubscriberApi = NewsletterSubscriberApi();

	const updateConfirmationStatus = async() => {
		try {
			const subscriberInfo = await newsletterSubscriberApi?.getSubscriberinfo('', '', Number(id));

			if(!subscriberInfo?.data?.attributes?.confirmed){
				const confirmationStatus = await newsletterSubscriberApi?.updateSubscriberConfimationStatus(Number(id) , true);
				
				if(confirmationStatus === 200) {

					setStatusText(`${t('statusSuccess')}`);
					setIsSuccess(true);

					setTimeout(() => {
						navigate('/');
					}, 5000);

				}else{
					setStatusText(`${t('statusFail')}`);
				}
			}else{
				setStatusText(`${t('statusSuccess')}`);
				setIsSuccess(true);

				setTimeout(() => {
					navigate('/');
				}, 5000);

			}
		} catch (error) {
			console.log('Error:', error);
			displayNotification('Something Went Wrong Fetching Clients, Please Try Again !', 'error');
		}
	}

	useEffect(() => {
		updateConfirmationStatus();
		// eslint-disable-next-line
	}, [id])

	return (
		<>
			<Nav isBgWhite={true} />		
			<Notice 
				tag={isSuccess ? t('tag') : ''}
				title={statusText}
				subtitle={isSuccess ? t('subtitle') : ''}
				success={isSuccess}
			/>
		</>
	)
}

export default SubscriberConfirmation;