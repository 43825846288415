import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import SeoDataHandler from "utils/SeoDataHandler";

import { SeoBE, SeoFE } from "types/SharedType";

import ContactUsSeoApi from "api/ContactUsApi";

import SeoComponent from "components/common/seoComponent/SeoComponent";
import Nav from "components/layout/nav";
import Footer from "components/layout/footer";
import ContactUsIntroduction from "./components/contactUsIntroduction";
import ContactUsForm from "./components/contactUsForm";
import ContactUsQuestions from "./components/contactUsQuestions";

interface ContactUsSeoBE {
	seo: SeoBE;
}

declare global {
	interface Window {
		gtag: any;
	}
}

const ContactUs = () => {
	const { i18n } = useTranslation();

	const [seo, setSEO] = useState<SeoFE>();

	const contactUsSeoApi = ContactUsSeoApi();
	const seoDataHandler = SeoDataHandler();

	const handleContactUsSeo = async () => {
		try {
			const constUsSeoData: ContactUsSeoBE =
				await contactUsSeoApi.getContactUsSeoData(i18n.language);

			const seo = seoDataHandler.handleSeoData(constUsSeoData.seo);

			seo && setSEO(seo);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		handleContactUsSeo();
		// eslint-disable-next-line
	}, [i18n.language]);
	return (
		<>
			{seo && (
				<SeoComponent
					metaTitle={seo?.metaTitle}
					metaDescription={seo?.metaDescription}
					metaImage={seo?.metaImage}
					metaSocial={seo?.metaSocial}
					canonicalURL={seo?.canonicalURL}
					keywords={seo?.keywords}
					metaRobots={seo?.metaRobots}
					metaViewport={seo?.metaViewport}
					structuredData={seo?.structuredData}
				/>
			)}
			<Nav isBgWhite={true} />

			<ContactUsIntroduction />

			<ContactUsForm />

			<ContactUsQuestions />

			<Footer />
		</>
	);
};

export default ContactUs;
