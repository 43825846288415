import { useContext } from 'react';
import { NotificationContext } from 'providers/notificationProvider';

import axios from 'axios';

const ContactUsSeoApi = () => {

	const { displayNotification } = useContext(NotificationContext);

	const getContactUsSeoData = async(locale: string) => {
		const result = await axios.get(process.env.REACT_APP_STRAPI_API_URL + `/contact-us-page?populate=seo.metaImage,seo.metaSocial.image&locale=${locale}`)
		.then(response => {
				return response?.data?.data?.attributes;
		}).catch(error => {
				displayNotification(`Something Went Wrong Fetching Insight Data, Please Try Again !`, 'error');
				console.error(`[Error - API] Error calling Insight data`, error);
		});
		return result;
	}


	return {
		getContactUsSeoData: getContactUsSeoData,
	};
}

export default ContactUsSeoApi;