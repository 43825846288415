import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import FullWidth from 'components/common/fullWidth';

import './TailoredSolution.scss';
import CustomButton from 'components/common/customButton';


const TailoredSolution = () => {
	const {t} = useTranslation('caseStudies');
	const navigate = useNavigate();

	return (
		<FullWidth className='tailored-solution'>
			<div className='tailored-solution-container'>
				<div className='left-column'>
					<div className='title'>
						{t('tailoredSolutionsTitle')}
					</div>
				</div>

				<div className='right-column'>
					<div className='description'>
						{t('tailoredSolutionsDescription')}
					</div>

					<CustomButton
						onClickBtn={() => navigate('/contact-us')}
					>
						{t('tailoredSolutionsBtn')}
					</CustomButton>
				</div>
			</div>
		</FullWidth>
	);
}

export default TailoredSolution;