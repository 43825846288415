import Nav from "components/layout/nav";
import Footer from "components/layout/footer";

import PageNotFound from "./components/pageNotFound";
import { Helmet } from "react-helmet-async";

const Page404 = () => {
	return (
		<>
			<Helmet>
				<title>404 - Page Not Found</title>
				<meta name="robots" content="noindex" />
				<meta name="description" content="Page not found." />
				<link rel="canonical" href={window.location.href} />
				<script>{`document.status = 404`}</script>
			</Helmet>
			<Nav isBgWhite={true} />

			<PageNotFound />

			<Footer />
		</>
	);
};

export default Page404;
