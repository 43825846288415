import { useContext } from 'react';
import { NotificationContext } from 'providers/notificationProvider';

import axios from 'axios';

const EventApi = () => {

	const { displayNotification } = useContext(NotificationContext);

	const getEventData = async(id: string, locale: string) => {
		const result = await axios.get(process.env.REACT_APP_STRAPI_API_URL + `/events?populate=seo.metaImage,seo.metaSocial.image,detail.banner_image,image_list,event_types,localizations&locale=${locale}&filters[url_path]=${id}`)
		.then(response => {
				return response?.data?.data[0]?.attributes;
		}).catch(error => {
				displayNotification(`Something Went Wrong Fetching Insight Data, Please Try Again !`, 'error');
				console.error(`[Error - API] Error calling Insight data`, error);
		});
		return result;
	}

	const getEventListData = async (locale: string, filter?: string) => {

		let params = '';

		if(filter === 'incoming'){

			const date = new Date().toISOString();

			params = `&filters[Date_Start][$gt]=${date}`;
		}

		const result = await axios.get(process.env.REACT_APP_STRAPI_API_URL + `/events?locale=${locale}&populate=detail.banner_image,image_list,event_types${params}`)
		.then(response => {
				return response?.data?.data;
		}).catch(error => {
				displayNotification(`Something Went Wrong Fetching Insight Type Data, Please Try Again !`, 'error');
				console.error(`[Error - API] Error calling Insight Type data`, error);
		});
		return result;
	}

	return {
		getEventData: getEventData,
		getEventListData: getEventListData,
	};
}

export default EventApi;