import { useContext } from 'react';
import { NotificationContext } from 'providers/notificationProvider';

import axios from 'axios';

const SolutionApi = () => {

	const { displayNotification } = useContext(NotificationContext);

    const getSolutionsListData = async(locale: string) => {
		const result = await axios.get(process.env.REACT_APP_STRAPI_API_URL + `/solutions?locale=${locale}&populate=detail.banner_image,white_paper_detail&sort=rank`)
		.then(response => {
			return response?.data?.data;
		}).catch(error => {
			displayNotification('Something Went Wrong Fetching Solution List, Please Try Again !', 'error');
			console.error("[Error - API] Error calling getSolutionsListData", error);
		});
	
		return result;
    }

	const getSolutionData = async(locale: string, filter?: string, targetPopulateSection?: string) => {

		const defaultPopulateSection = 'detail.banner_image,white_paper_detail,development_services.services.icon.icon,development_process.steps,tools.logo.logo,selected_case_studies.case_studies.banner_image,approach.steps,reasons_to_choose_us.list_of_reasons,security_and_reliability';

		const result = await axios.get(
			process.env.REACT_APP_STRAPI_API_URL + `/solutions?locale=${locale}&populate=seo.metaImage,seo.metaSocial.image,${targetPopulateSection ? targetPopulateSection : defaultPopulateSection}&filters[urlPath]=/${filter}`
		)
		.then(response => {
			return response?.data?.data[0]?.attributes
		}).catch(error => {
				displayNotification('Something Went Wrong Fetching a Solution, Please Try Again !', 'error');
				console.error("[Error - API] Error calling getSolutionData", error);
		})
		
		return result;
	}

	return {
		getSolutionsListData: getSolutionsListData,
		getSolutionData: getSolutionData,
	};
}

export default SolutionApi;